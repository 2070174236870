import React, { Component } from 'react'


export const ProjectConfig = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')?{
  api_url: "http://simpleflex.localhost.com/api/",
  flexapi : "http://localhost:3000",
  google: {
    isActive: false,
    instance: '',
    zone: '',
    client_id: '',
    project_id: '',
    auth_uri: 'https://accounts.google.com/o/oauth2/auth',
    token_uri: 'https://oauth2.googleapis.com/token',
    auth_provider_x509_cert_url: 'https://www.googleapis.com/oauth2/v1/certs',
    client_secret: '',
    redirect_uris: ['https://simpleflex.us/google-receiver'],
  },
  STRIPE_PUBLIC_KEY: "pk_live_51NjtfJFtzes8YXSvee3CW3e8TvD2fOSTADzpKbMJRek0lSwCyYrqTFkumdBeYytMrYmWR6WZ5JmT2K6gZSxU2qLA000TMo391E",
}
:
{
  api_url: "https://api.simpleflex.us/api/",
  flexapi : "https://flexapi.simpleflex.us",
  google: {
    isActive: false,
    instance: '',
    zone: '',
    client_id: '',
    project_id: '',
    auth_uri: 'https://accounts.google.com/o/oauth2/auth',
    token_uri: 'https://oauth2.googleapis.com/token',
    auth_provider_x509_cert_url: 'https://www.googleapis.com/oauth2/v1/certs',
    client_secret: '',
    redirect_uris: ['https://simpleflex.us/google-receiver'],
  },
  STRIPE_PUBLIC_KEY: "pk_live_51NjtfJFtzes8YXSvee3CW3e8TvD2fOSTADzpKbMJRek0lSwCyYrqTFkumdBeYytMrYmWR6WZ5JmT2K6gZSxU2qLA000TMo391E",
} 





export const UserTheme = {

  typography: {
    fontFamily: [
      'Poppins',
      'sans-serif'
    ].join(','),
    fontSize: 13,
    fontWeightLight: 300,
    fontWeightRegular: 300,
    fontWeightMedium: 300,
    fontWeightBold: 700,
  },
  palette: { 
    primary: {
      light: '#eb5c24',
      main: '#eb5c24',
      dark: '#84300f',
      contrastText: '#fff',
    },
    secondary: {
      light: '#e69203',
      main: '#e69203',
      dark: '#cb8308',
      contrastText: '#fff',
    },
    error: {
      main: '#f44336',
      contrastText: '#fff',
    },
    background: {
      default: '#fafafa',
    },
  },



}