import { Autocomplete, Button, createFilterOptions, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, MenuItem, Modal, Paper, Select, styled, Switch, SwitchProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material"
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "elements/Box";
import ic_dots from 'icons/ic_dots.svg';
import {ProjectConfig} from 'Global';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import EventIcon from '@mui/icons-material/Event';
import CameraAltIcon from '@mui/icons-material/CameraAlt';

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));


 //TODAY DATE FORMATED MM/DD/YYYY
 const today = new Date('2014-08-18T21:11:54')


 export default function UserEditor(props: any) {

  const {setLoading} = props;

  useEffect(() => {
    gerResellerList();
    setLoading(false);
  }, []);
  

  /*=======================================
    GET USER ID FROM URL OR FROM ACCOUNT USER ID
  =======================================*/
  let {user_id } = useParams();
  user_id = props.account_user_id ? props.account_user_id : user_id;
  /*=======================================
  =======================================*/
  

  const navigate = useNavigate();
  const defaultValues: any = {
    user_id: user_id ? user_id : "null",
    project_id: "",
    name: "",
    last_name: "",
    email: "",
    role_id: "",
    is_active: true,
    phone: "",
    reseller_id: "",
  };
  

  const [formValues, setFormValues] = useState(defaultValues);
  const [roles_list, setRolesList] = useState<any[]>([]);
  const [files, setFiles] = useState<any>([]);
  const [passwordValues, setPasswordValues] = useState({ 
    user_id: user_id ? user_id : "null",
    password: '', 
    confirmPassword: '' 
  }); // For password change

  const role_id = localStorage.getItem('role_id');

  /*=================================================
    GET ROLE LIST
  =================================================*/
  useEffect(() => {
    axios.get(ProjectConfig.api_url,
    {
      params: {
        request: 'role_list',
      }
    })
    .then((response) => {
      setRolesList(response.data);
    })
    .catch((err) => {
      console.log(err.data);
    });
  }, []);
  /*=================================================
  =================================================*/


  const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  // Handle input change for password and confirmPassword
  const handlePasswordInputChange = (e: { target: { name: string; value: string; }; }) => {
    const { name, value } = e.target;
    setPasswordValues({
      ...passwordValues,
      [name]: value,
    });
  };


  const handleSubmit = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("files", files);

    const ltkn = localStorage.getItem("ltkn");
    
    axios.post(ProjectConfig.api_url, formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "LTKN": ltkn,
        },
        params: {
          request: 'add_edit_user',
          formdata: formValues,
        }
      })
        .then((response) => {
          console.log("res",response);
          
          if(props.account_user_id){
            setLoading(false);
          } else {
            navigate('/users');
          }
           
        })
        .catch((err) => {
          console.log(err.data);
        });
  };


  const handleChangePassword = () => {
    if (passwordValues.password !== passwordValues.confirmPassword) {
      alert('Passwords do not match');
      return;
    }

    setLoading(true);
    const ltkn = localStorage.getItem("ltkn");

    axios.post(ProjectConfig.api_url, passwordValues,
    {
      headers: {
        "LTKN": ltkn,
      },
      params: {
        request: 'update_user_password',
        params: passwordValues,
      }
    })
      .then((response) => {
        console.log(response.data);
        alert("Password changed successfully");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.data);
        setLoading(false);
      });
  };


  useEffect(() => {
    if (user_id) {
      axios
        .get(ProjectConfig.api_url, {
          params: {
            request: "user_details",
            user_id: user_id,
          },
        })
        .then((response) => {
          setFormValues(response.data);
        })
        .catch((err) => {
          console.log(err.data);
        });
    }
  }, [user_id]);


    const [resellers_list, setResellersList] = useState<any>([]);

    /*=========================================================
    GET PLANS LIST
    =========================================================*/
    const gerResellerList = () => {
      setLoading(true);
      axios.get(ProjectConfig.flexapi+"/resellers/list",
      {
          headers: {
            ltkn: localStorage.getItem("ltkn"),
          },
          params: {
            resellerSelector: true,
          }
      })
          .then((response) => {
          console.log("resellers",response.data);
          setResellersList(response.data.list);
          setLoading(false);
          })
          .catch((err) => {
          console.log(err.data);
          });
    };


  return (
      <div id="userForm">
        <div className="userForm_right">
          <Box id="project_editor" className="box_editor forced_box_editor" title="User" subtitle="Edit"  >
            <div className='form'>
              <div className='input_group'>
                <h2>Personal Info</h2>
                <div className='input_row'>
                  <TextField 
                    id="name"
                    name="name"
                    label="Name"
                    type="text"
                    required
                    fullWidth
                    value={formValues.name}
                    onChange={handleInputChange}
                  />

                  <TextField
                    id="last_name"
                    name="last_name"
                    label="Last Name"
                    type="text"
                    required
                    fullWidth
                    value={formValues.last_name}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className='input_group'>

                  <TextField
                    id="email"
                    name="email"
                    label="Email"
                    type="text"
                    required
                    fullWidth
                    value={formValues.email}
                    onChange={handleInputChange}
                  />
                </div>

              <TextField
                id="phone"
                name="phone"
                label="Phone"
                type="text"
                required
                fullWidth
                value={formValues.phone}
                onChange={handleInputChange}
              />

              {(formValues.role_id === "1" && role_id === "2") &&
                <FormControl variant="outlined" className="select" fullWidth>
                  <InputLabel id="demo-simple-select-outlined-label">Reseller</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={formValues.reseller_id}
                    fullWidth
                    sx={{ width: "100%" }}
                    onChange={(e) => {
                      setFormValues({
                        ...formValues,
                        ["reseller_id"]: e.target.value
                      });
                    }}
                    label="Reseller Plan"
                  >
                    {resellers_list.map((item: any, index: number) => {
                      return (
                        <MenuItem key={index} value={item.user_id}>
                          {item.name} {item.last_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              }

              {props.account_user_id ? null :
                <FormControlLabel
                  checked={formValues.is_active}
                  control={
                    <IOSSwitch 
                      sx={{ m: 1 }}
                      onChange={(e) => {
                        setFormValues({
                          ...formValues,
                          ["is_active"]: e.target.checked
                        });
                      }}
                    />
                  }
                  label="Is Active"
                />
              }
            </div>
            <div className='buttons'>
              <Button onClick={() => navigate(-1)} variant="outlined" className="button_2">Cancel</Button>
              <Button onClick={handleSubmit} variant="contained" className="button_1">Submit</Button>
            </div>
          </Box>

          <Box title="Security" subtitle="Edit" >
            <div className='input_group'>
              <TextField
                id="password"
                name="password"
                label="Password"
                type="password"
                required
                fullWidth
                value={passwordValues.password}
                onChange={handlePasswordInputChange}
                autoComplete="new-password"
              />
              <TextField
                id="confirmPassword"
                name="confirmPassword"
                label="Confirm Password"
                type="password"
                required
                fullWidth
                value={passwordValues.confirmPassword}
                onChange={handlePasswordInputChange}
                autoComplete="new-password"
              />
            </div>

            <Button 
              onClick={handleChangePassword} 
              variant="contained" 
              className="button_1"
            >
              Change Password
            </Button>
          </Box>
        </div>
      </div>
  );
}
