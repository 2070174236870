import { Button, Dialog, DialogContent, TextField } from "@mui/material";
import axios from "axios";
import ISwitch from "elements/ISwitch";
import { ProjectConfig } from "Global";
import { useEffect, useState } from "react";
import moment from "moment";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';

const defaultValues = {
    start: '00:00',
    end: '00:00',
    recurrent: false,
}
    

export default function Scheduler(props: any) {

    const {
        account_id,
        status: scheduler_status_button,
        loadList,
        disabled
    } = props;

    const [scheduler_status, setSchedulerStatus] = useState(false);
    const [formValues, setFormValues] = useState(defaultValues);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if(open){
            get_account_scheduler();
        }
    },[open, account_id])

    const get_account_scheduler = () => {
        axios
        .get(ProjectConfig.flexapi+"/account/scheduler", {
          params: {
            account_id: account_id
          },
        }) 
        .then((response) => { 
          console.log("response",response.data);
          setFormValues(response.data);
          setSchedulerStatus(response.data.scheduler_status);
        })
        .catch((err) => {
          console.log("err",err);
        })
    }


    

    const handleSubmit = () => {

        const body = {
            ...formValues,
            scheduler_status,
            account_id
        }

        axios.post(ProjectConfig.flexapi+"/account/updateScheduler", body,{
        })
        .then((response) => { 
            console.log("response",response.data);
        })
        .catch((err) => {
            console.log("err",err.data);
        })
        .finally(() => {
            setOpen(false);
            loadList();
        })
    }

    return (
        <>
        <Button
            variant="contained"
            onClick={() => setOpen(true)}
            startIcon={<TimerOutlinedIcon />}
            sx={{
                backgroundColor: (scheduler_status_button) ? 'black' : '#873e23',
                color: 'white',
                '&:hover': {
                    backgroundColor: (scheduler_status_button) ? 'black' : '#873e23',
                }
            }}
            disabled={disabled}
        >
            {scheduler_status_button ? "Programado" : "Programar"}
        </Button>
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
        
        >
            <DialogContent  className="forced_box_editor">
                <div className="input_row">
                    <h2>Programar </h2>
                    <ISwitch
                        id="status"
                        checked={scheduler_status}
                        onChange={(e: any) => {
                            setSchedulerStatus(e.target.checked)
                        }}
                    />
                </div>
                

                <div className="inputColumn">
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    {/* WAREHOUSE - START TIME */}
                    <TimePicker
                        label="Start Time"
                        value={moment(formValues.start, "HH:mm")}
                        ampm={false}
                        minutesStep={5}
                        // format = "HH:mm"
                        inputFormat = "HH:mm"

                        onChange={(newValue) => {
                            formValues.start = (newValue)? (newValue).format('HH:mm') : '00:00';
                            setFormValues({...formValues});
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        disabled={!scheduler_status}
                    />
                    
                    {/* WAREHOUSE - END TIME */}
                    <TimePicker
                        label="End Time"
                        value={moment(formValues.end, "HH:mm")}
                        ampm={false}
                        minutesStep={5}
                        onChange={(newValue) => {
                            formValues.end = (newValue)? (newValue).format('HH:mm') : '00:00';
                            setFormValues({...formValues});
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        disabled={!scheduler_status}
                    />
                    </LocalizationProvider> 
                    <ISwitch
                        id="recurrent"
                        label="Recurrent"
                        checked={formValues.recurrent}
                        onChange={(e: any) => {
                            setFormValues({
                                ...formValues,
                                recurrent: e.target.checked
                            })
                        }}
                        disabled={!scheduler_status}

                    />
                </div>


                <div className="buttons">
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => setOpen(false)}
                    >
                        Cancel
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleSubmit()}
                    >
                        Save
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
        </>
    )
}