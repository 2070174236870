import axios from 'axios';
import { useEffect, useState } from 'react'
import Box from 'elements/Box';
import { Avatar, Button, Chip, CircularProgress, Dialog, DialogContent, DialogContentText, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {ProjectConfig} from 'Global';
import moment from 'moment';
import AppAvatar from 'elements/AppAvatar';
import Cookies from 'universal-cookie';
import ic_empty from 'icons/ic_empty.svg';
import AmazonLogs from './AmazonLogs';
import EventIcon from '@mui/icons-material/Event';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import AlertDialog from 'elements/AlertDialog';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { useSnackbar, withSnackbar } from 'notistack';
import StopIcon from '@mui/icons-material/Stop';
import TimerIcon from '@mui/icons-material/Timer';
import Orders from './Orders';
import AccountMenuUsers from './UsersAccountMenu';
import Scheduler from './Scheduler';

const cookies = new Cookies();


export default withSnackbar(
  function AmazonAccountsList(props: any) {

  /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  /*============================================= */
    
  const {setLoading} = props;
  

  const [account_list, setAccountList] = useState<any>([]);
  const [total_pages, setTotalPages] = useState(0);

  const queryParams = new URLSearchParams(window.location.search);
  const currentPage=parseInt(localStorage.getItem('page') || "1");

  const [page, setPage] = useState(currentPage);
  const category = queryParams.get('category');
  const [refreshList, setRefreshList] = useState(0);

  const [alertOpen, setAlertOpen] = useState(false);

  /*=========================================================
  LOAD LIST            
  =========================================================*/

  useEffect(() => {

    loadList();
    
  }, [page, refreshList])
  /*=========================================================*/

  const loadList = () => {
    setLoading(true)
      axios.get(ProjectConfig.api_url,
      {
        headers:{
          'LTKN': cookies.get('ltkn'),
        },
        params: {
          request: 'af_accounts_list',
          params: {
            page: page,
          }
        }
      })
        .then((response) => {
          console.log(response.data);
          setAccountList(response.data.account_list)
          setTotalPages(response.data.total_pages)
          setLoading(false)
          //add gettings blocks : false to each account
           
        })
        .catch((err) => {
          console.log(err.data);
        })
        localStorage.setItem('page', page.toString())
  }



  /*=========================================================
  DELETE ITEM
  =========================================================*/

  const handleDelete = (account_id:number) => {
    axios.post(ProjectConfig.api_url, null, 
    {
      params: {
        request: 'delete_af_account',
        account_id: account_id,
      }
    })

    .then((response) => {
      console.log(response.data);
      setRefreshList(refreshList + 1);
      
    })
    .catch((err) => {
      console.log(err.data);
    })
  }
  /*=========================================================*/
  const [hasReseller, setHasReseller] = useState(false);

  const [activeItem, setActiveItem] = useState<any>();
  const [activeId, setActiveId] = useState(0);

  const [dialogForcedStop, setDialogForcedStop] = useState(false);
  const [accountIdToStop, setAccountIdToStop] = useState(0);


  /*=========================================================
    START RUNNING
  =========================================================*/
  const startrunning = async () => {
    setLoading(true)
    const account_id = activeItem;

    let i = account_list.findIndex((account:any) => account.account_id === account_id);
    let newAccountList = [...account_list]
    newAccountList[i].running = true;
    setAccountList(newAccountList);

    await axios
    .get(ProjectConfig.flexapi+"/offers/getBlocks", { 
      params: {
        account_id: account_id
      },
    })
    .then((response) => { 
      console.log("response",response.data);
      if(response.data.status === "success"){
        enqueueSnackbar('Success', { variant: 'success' });
      }else if(response.data.status === "finished"){
        enqueueSnackbar('Success', { variant: 'success' });
      }else{
        enqueueSnackbar(response.data.message, { variant: 'error' });
      }

      

      // let newAccountList = [...account_list]
      // newAccountList[i].running = false;
      // setAccountList(newAccountList);


    })
    .catch((err) => {
      console.log("err",err.data);
    })
    .finally(() => {
      setLoading(false)
    })
    loadList();

  }
  /*=========================================================*/



  /*=========================================================
    FORCED STOP
  =========================================================*/
  const setForcedStop = async (account_id:number) => {
    await axios
    .post(ProjectConfig.flexapi + "/stopProcess", {
      account_id: account_id,
    })
    .then((response) => {
      enqueueSnackbar("Account Stopped", { variant: "error" });
      setAccountIdToStop(0);
      setDialogForcedStop(false);
      loadList();
    })
    .catch((error) => {
      console.error(error);
      enqueueSnackbar("Failed to stop account", { variant: "error" });
    });
  }
  /*=========================================================*/



  /*=========================================================
  DIALOG FORCED STOP ATTEMPTS
  =========================================================*/

  useEffect(() => {
    if(dialogForcedStop){
      setForcedStop(accountIdToStop);
    }
  }, [dialogForcedStop])

  

  /*=========================================================*/

  const [openOrders, setOpenOrders] = useState(false);
  const [openTimeForm, setOpenTimeForm] = useState(false);



  useEffect(() => {
    const interval = setInterval(() => {
      loadList();
    }, 60000); // 1 minuto
  
    return () => clearInterval(interval);
  }, []);

  
  return (
    <div id='AccountsList' className='ModuleContainer'>
      

        
      <div className='boxContainer'>

        <div className='boxContainerHeader'>
          <div className='boxContainerTitle'>
            <h2 className='h2_title'>Accounts</h2>
            <span className='h2_subtitle'>List of linked accounts</span>
          </div>
          <div className='boxContainerActions'>
            <Button component={Link} to="/amazon-accounts/add" variant="contained" startIcon={<AddIcon />}>
              Add Account
            </Button>
          </div>
        </div>
        
        <Box id='account_list'>
          {account_list.length > 0 ? (
            <TableContainer>
              <Table> 
                <TableBody>
                    {account_list.map((account:any,i:any) => 
                    {
                      console.log(account);
                      
                      return (
                      <TableRow 
                        key={i}
                        hover
                        role = "checkbox"
                      >
                        <TableCell>
                          <div className='td_column_avatar'>
                            <AppAvatar name={account.name} image={account.image} />
                            <div className='td_column'>
                              <span className='td_column_title'>{account.name}</span>
                              <span className='td_column_subtitle'>{account.email}</span>
                              <span className='time_left'>{account.time_left_label}</span>
                            </div>
                          </div>
                        </TableCell>

                        <TableCell align='right' className='td_buttons'>

                   
                          


                          
                          <div className='userMenuContainer'>
                          {(account.is_active)?  (
                            account.time_left > 0 ? (
                            <div className='FlexContainer'>
                              <Button
                              
                                variant="contained"
                                color="success"
                                fullWidth
                                style={{marginRight: "10px"}}
                                disabled={account.status === "running" || account.status === "paused"}
                              startIcon={account.status === "running"? <CircularProgress sx={{color: "white"}} size={16} /> : account.status === "paused"? <TimerIcon /> : <PlayArrowIcon />}
                              
                                onClick={() => {
                                  setActiveItem(account.account_id);
                                  setAlertOpen(true);
                                }}
                              >
                                {account.status === "running" && "Running"}
                                {account.status === "paused" && "Waiting"}
                                {account.status === "stopped" && "Start"}
                              </Button>
                            

                              {(account.status === "running" || account.status === "paused") && (
                                <Button
                                  variant="contained"
                                  color="error"
                                  fullWidth
                                  style={{marginRight: "10px"}}
                                  startIcon={<StopIcon sx={{color: "white"}}/>}
                                  onClick={() => {
                                    setAccountIdToStop(account.account_id);
                                    setDialogForcedStop(true); 
                                  }}
                                >
                                  Stop
                                </Button>
                              )}
                              <Scheduler
                                account_id={account.account_id}
                                status={account.scheduler_status}
                                loadList={loadList}
                                disabled={account.status === "running" || account.status === "paused"}
                              />
                              
                            </div>
                            ) : (
                              <Tooltip title="Contact support to activate this account">
                                <Chip label="Account expired" color="error" size='small' className='accountStatusChip' />
                              </Tooltip>
                            )
                          )
                          : (
                            <Tooltip title="Contact support to activate this account">
                              <Chip label="Account is not active" color="error" size='small' className='accountStatusChip' />
                            </Tooltip>
                          )}

                          

                          <AccountMenuUsers 
                            setActiveItem={setActiveItem}
                            account={account}
                            setOpenOrders={setOpenOrders}
                            handleDelete={handleDelete}
                            account_id={activeId}
                            setAccountId = {setActiveId}
                            setHasReseller={setHasReseller}
                          />
                          </div>


                          {/* <IconButton aria-label="delete"  color="primary" onClick={() => handleDelete(account.account_id)}>
                            <DeleteOutlineOutlinedIcon />
                          </IconButton> */}
                        </TableCell>
                      </TableRow>
                    )})}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <div className="noProducts">
              <img src={ic_empty} alt=""/>
              <p>No accounts found</p>
            </div>
          )}
                    
          {total_pages > 1 && (
            <Pagination count={total_pages} page={page}  onChange={(event, value) => {
              setPage(value)
            }
            } 
            color="primary"
            style={{"marginTop": "20px","alignSelf": "center"}}
            />
          )}
        </Box>
      </div>

      <AmazonLogs setLoading={setLoading} />

      <AlertDialog 
        open={alertOpen} 
        setOpen={setAlertOpen} 
        send={startrunning}
        subtitle="Are you sure you want to start getting blocks?"
      />


      <Dialog 
        open={dialogForcedStop}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div className="dialog_header">
              <h2 className='h2-no-margin'>Stopping Getting Blocks</h2>
              
          </div>
          <div className='flex-column-center'>
            <CircularProgress sx={{marginTop: "20px", marginBottom: "20px"}} size={30} />
            <p>Stopping getting blocks... Please wait</p>
          </div> 
        </DialogContent>
      </Dialog>


      <Orders
        setLoading={setLoading}
        open={openOrders}
        setOpen={setOpenOrders}
        account_id={activeId}
        account={activeItem}
        loadList={loadList}
        setAccountId={setActiveId}
        isUser={true}
        hasReseller={hasReseller}
      />

 
    </div>
  );
});

