import Cookies from 'universal-cookie';

import * as React from 'react';
import {Link, Avatar, Button, Container, TextField, Typography, Dialog, DialogContent, InputAdornment, IconButton} from '@mui/material';
import Box from "elements/Box";
import { ProjectConfig } from 'Global';
import axios from 'axios';
import { useSnackbar, withSnackbar } from 'notistack';
import LoadingButton from '@mui/lab/LoadingButton';
import { useEffect, useState } from 'react';
import { resolve } from 'path';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useSearchParams } from 'react-router-dom';
import FormLogin from '../FormLogin';
import FormChangePass from '../FormChangePass';
import FormCredentialsSent from '../FormCredentialsSent';
import FormForgotPass from '../FormForgotPass';


import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import EditLocationIcon from '@mui/icons-material/EditLocation';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import LanguageIcon from '@mui/icons-material/Language';
import './landing.css';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TelegramIcon from '@mui/icons-material/Telegram';




const cookies = new Cookies();

const windowHeight = window.innerHeight;

export function Landing(props: any) {



  /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  /*============================================= */

  
  const [state, setState] = useState('login');
  const [userEmail,setUserEmail] = useState('');
  const [email,setEmail] = useState('');
  const [code,setCode] = useState('');

  const [searchParams,setSearchParams] = useSearchParams();
  
  useEffect (() => {
    setLoading(false);
    setEmail(searchParams.get('email') || '');
    setCode(searchParams.get('code') || '');
  }, []);

  useEffect (() => {
    if (email && code) {
      setState('changePass');
    }
  }, [code]);


  const [loadingButton, setLoadingButton] = useState(false);

  const defaultValues: any = {
    name: '',
    lastname: '',
    email: '',
    password: '',
    password_confirmation: '',
  };

  const [formValues, setFormValues] = React.useState(defaultValues);
  
  const [openRegister, setOpenRegister] = React.useState(false);

  const setLoading = props.setLoading;

  useEffect (() => {
    setLoading(false);
  }, []);


  useEffect(() => {
    setFormValues(defaultValues);
  }, [openRegister]);
    
  //IF TOKEN MOBILE APP EXISTS IN URL 
  const tokenApp = localStorage.getItem('tokenApp')? localStorage.getItem('tokenApp') : '';

  
  /*================================================
  ================================================*/

  const [showPassword, setShowPassword] = React.useState(false);
  return (
    <>
      <div id="loginContainer">
        <div id="landingDiv">
          <div className="landingContent">
            <div
              id="section1"
              className="landing_section"
              style={{
                minHeight: windowHeight,
                backgroundImage: `url(/images/landing/1.png)`,
              }}
            >
              <div className="landing_section_overlay">
                <span className="title1">WELCOME TO</span>
                <span className="title2">SIMPLE FLEX</span>
                <span className="text1">
                  The most effective system to capture your blocks with
                  Increased Price
                </span>
              </div>
            </div>

            <div
              id="section2"
              className="landing_section"
              style={{
                minHeight: windowHeight,
                backgroundImage: `url(/images/landing/2.png)`,
              }}
            >
              <div className="landing_section_overlay">
                <div className="content_align_center">
                  <span className="title1">Our filters</span>
                  <span className="title2">will allow you to select:</span>
                  <div className="s2_list">
                    <div className="d-flex">
                      <div className="s2_ic_container">
                        <CurrencyExchangeIcon />
                      </div>

                      <span className="s2_text">
                        The minimum price per hour
                      </span>
                    </div>

                    <div className="d-flex">
                      <div className="s2_ic_container">
                        <AccessAlarmIcon />
                      </div>

                      <span className="s2_text">
                        Start and end time of your block
                      </span>
                    </div>

                    <div className="d-flex">
                      <div className="s2_ic_container">
                        <EditLocationIcon />
                      </div>

                      <span className="s2_text">
                        The stations you like the most
                      </span>
                    </div>
                  </div>

                  <div className="s2_list2">
                    <div className="d-flex">
                      <div className="s2_ic_container">
                        <SportsScoreIcon />
                      </div>
                      <div>
                        <span className="s2_text">
                          You can do the
                          <b>"I’VE ARRIVED"</b> if you are late or need to skip
                          the Selfie.
                        </span>
                      </div>
                    </div>

                    <div className="d-flex">
                      <div className="s2_ic_container">
                        <NotificationsActiveIcon />
                      </div>
                      <div>
                        <span className="s2_text">
                          When you capture a block you will immediately receive
                          SMS notifications.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              id="section3"
              className="landing_section"
              style={{
                minHeight: windowHeight,
                backgroundImage: `url(/images/landing/3.png)`,
              }}
            >
              <div className="landing_section_overlay">
                <div>
                  <span className="title1">Try it for FREE</span>
                  <span className="title2">for 3 days</span>

                  <div id="s3_titles" className="center_text">
                    <span className="title3">
                      Available in all Cities Nationwide
                    </span>
                    {/* <span className="title3">We have different plans for you:</span> */}
                  </div>

                  <div className="plans">
                    <div className="plan">
                      <span className="plan_price">$45</span>
                      <span className="plan_time">1 Week</span>
                    </div>
                  </div>

                  <span id="s3_endtext" className="text1">
                    Refer a friend and automatically get <b>3 days free</b> for
                    each one.
                  </span>
                </div>

                <div id="contactus_container">
                  <span className="title4">Contact us</span>
                  <div id="contactus_2_elements">
                    <div className="d-flex">
                      <div className="s2_ic_container">
                        <WhatsAppIcon />
                      </div>
                      <a href="https://wa.me/13213576089" target="_blank">
                        <span className="s2_text">+1 (321) 357-6089</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="loginDiv">
          {state === "login" && (
            <FormLogin
              setLoading={setLoading}
              setState={setState}
              email={userEmail}
              setEmail={setUserEmail}
            />
          )}
          {state === "forgotPass" && (
            <FormForgotPass
              setLoading={setLoading}
              setState={setState}
              email={userEmail}
              setEmail={setUserEmail}
            />
          )}
          {state === "credentialsSent" && (
            <FormCredentialsSent setState={setState} setEmail={setUserEmail} />
          )}
          {state === "changePass" && (
            <FormChangePass
              setLoading={setLoading}
              setState={setState}
              email={email}
              code={code}
            />
          )}
        </div>
      </div>
    </>
  );
}
