import axios from 'axios';
import { useEffect, useState } from 'react'
import Box from 'elements/Box';
import { Avatar, Button, Chip, CircularProgress, Dialog, DialogContent, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import { Link, useParams } from 'react-router-dom';

import {ProjectConfig} from 'Global';
import moment from 'moment';
import AppAvatar from 'elements/AppAvatar';
import Cookies from 'universal-cookie';
import ic_empty from 'icons/ic_empty.svg';
import AmazonLogs from './AmazonLogs';

import AddIcon from '@mui/icons-material/Add';
import AlertDialog from 'elements/AlertDialog';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { useSnackbar, withSnackbar } from 'notistack';
import ISwitch from 'elements/ISwitch';

import StopIcon from '@mui/icons-material/Stop';
import Filters from 'elements/Filters/Filters';
import TuneIcon from '@mui/icons-material/Tune';
import FiltersApplied from 'elements/Filters/FiltersApplied';
import TimerIcon from '@mui/icons-material/Timer';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import AccountMenu from './AccountMenu';
import Orders from './Orders';
import SpeedIcon from '@mui/icons-material/Speed';
import MultilineChartIcon from '@mui/icons-material/MultilineChart';
import TimeForm from './TimeForm';
import Scheduler from './Scheduler';

const cookies = new Cookies();


export default withSnackbar(
  function AdminAmazonAccountsList(props: any) {


  const {reseller_id} = props;
  const isReseller = reseller_id ? true : false;

  const savedFilters = localStorage.getItem('filters') || "{}";

  /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  /*============================================= */
    
  const {setLoading} = props;

  useEffect(() => {
    setLoading(false);
    //if local storage is empty, set default filters
  }, []);
  

  const [account_list, setAccountList] = useState<any>([]);
  const [total_pages, setTotalPages] = useState(0);

  const queryParams = new URLSearchParams(window.location.search);
  const currentPage=parseInt(localStorage.getItem('page') || "1");

  const [page, setPage] = useState(currentPage);
  const category = queryParams.get('category');
  const [refreshList, setRefreshList] = useState(0);

  const [alertOpen, setAlertOpen] = useState(false);

  const [dialogForcedStop, setDialogForcedStop] = useState(false);
  const [accountIdToStop, setAccountIdToStop] = useState(0);
  const [openOrders, setOpenOrders] = useState(false);
  const [openTimeForm, setOpenTimeForm] = useState(false);

  const [activeItem, setActiveItem] = useState<any>({});
  const [activeId, setActiveId] = useState(0);


  /*=========================================================
  FILTERS
  =========================================================*/
  const filters = [
    {type: "text", label: "Email", name: "email", value: ""},
    {type: "text", label: "Name", name: "account_name", value: ""},
    {type: "select", label: "Status", name: "status", value: "", options: [
      {label: "All", value: "all"},
      {label: "Active", value: "active"},
      {label: "Expired", value: "expired"},
      {label: "Running", value: "running"},
    ]},
  ]
  const [filtersValues, setFiltersValues] = useState<any>(JSON.parse(savedFilters));

  const setFilters = (tempFilters: any) => {
    setFiltersValues(tempFilters);
    //save in localstorage
    localStorage.setItem('filters', JSON.stringify(tempFilters));
  }
  /*=========================================================*/


  /*=========================================================
  LOAD LIST            
  =========================================================*/

  useEffect(() => {

    loadList();

  }, [page, refreshList])

  useEffect(() => {
    setPage(1);
    loadList();
  }, [filtersValues])

  /*=========================================================*/

  

  const loadList = () => {
    setLoading(true)
      axios.get(ProjectConfig.api_url,
      {
        headers:{
          'LTKN': cookies.get('ltkn'),
        },
        params: {
          request: 'af_accounts_list',
          params: {
            page: page,
            admin_list: true,
            filters: filtersValues,
            reseller_id: reseller_id
          }
        }
      })
        .then((response) => {
          console.log("loadlist",response.data);
          setAccountList(response.data.account_list)
          setTotalPages(response.data.total_pages)
          setLoading(false)
          //add gettings blocks : false to each account
           
        })
        .catch((err) => {
          console.log(err.data);
        })
        localStorage.setItem('page', page.toString())
  }



  /*=========================================================
  DELETE ITEM
  =========================================================*/

  const handleDelete = (account:any) => {
    setDeleteAlertOpen(true);
    setActiveItem(account);
  }


  const deleteItem = () => {
    axios.post(ProjectConfig.api_url, null, 
      {
        headers:{
          'LTKN': cookies.get('ltkn'),
        },
        params: {
          request: 'delete_af_account',
          account_id: activeItem.account_id
        }
      })
  
      .then((response) => {
        console.log(response.data);
        setRefreshList(refreshList + 1);
        
      })
      .catch((err) => {
        console.log(err.data);
      })
  }
  /*=========================================================*/

// call global snackbar



  


    /*=========================================================
    START RUNNING
  =========================================================*/
  const startrunning = async () => { 
    setLoading(true)
    const account_id = activeItem.account_id;

    let i = account_list.findIndex((account:any) => account.account_id === account_id);
    let newAccountList = [...account_list]
    newAccountList[i].running = true;
    setAccountList(newAccountList);

    await axios
    .get(ProjectConfig.flexapi+"/offers/getBlocks", {
      params: {
        account_id: account_id
      },
    })
    .then((response) => { 
      console.log("response admin ",response.data);
      if(response.data.status === "success"){
        enqueueSnackbar('Account Started', { variant: 'success' });
      }else if(response.data.status === "finished"){
        enqueueSnackbar('Success', { variant: 'success' });
      }else{
        enqueueSnackbar(response.data.message, { variant: 'error' });
      }


      // let newAccountList = [...account_list]
      // newAccountList[i].running = false;
      // setAccountList(newAccountList);


    })
    .catch((error) => {
      //get error code
      console.log("error: ",error);
    })
    .finally(() => {
      setLoading(false)
    })
    loadList();


  }
  /*=========================================================*/


    /*=========================================================
    FORCED STOP
  =========================================================*/
  const setForcedStop = async (account_id: number) => {
    await axios
      .post(ProjectConfig.flexapi + "/stopProcess", {
        account_id: account_id,
      })
      .then((response) => {
        enqueueSnackbar("Account Stopped", { variant: "error" });
        setAccountIdToStop(0);
        setDialogForcedStop(false);
        loadList();
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar("Failed to stop account", { variant: "error" });
      });
  };
  /*=========================================================*/



    /*=========================================================
  DIALOG FORCED STOP ATTEMPTS
  =========================================================*/

  useEffect(() => {
    if(dialogForcedStop){
      setForcedStop(accountIdToStop);
    }
  }, [dialogForcedStop])

  /*=========================================================*/

  const [open, setOpen] = useState(false);


const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);


useEffect(() => {
  const interval = setInterval(() => {
    loadList();
  }, 60000); // 1 minuto

  return () => clearInterval(interval);
}, []);

  return (
    <div id='AccountsList' className='ModuleContainer'>
      
      <div className='buttons_top'>
      

      </div>


      <div id="userWorkstationsHeader" className="boxContainerHeader">
            
          <div className="boxContainerTitle">
            <h2 className="h2_title">Accounts</h2>
            <span className="h2_subtitle">List of accounts registered</span>
          </div>
          
          <div className="boxContainerActions">
          <IconButton
              color="inherit"
              aria-label="menu"
              onClick={() => setOpen(true)}
            >
            <TuneIcon />
        </IconButton>

        <Filters open={open} setOpen={setOpen} filters={filters} setFilters={setFilters}/>
          </div>
          
      </div>

      <FiltersApplied filtersValues={filtersValues} setFiltersValues={setFiltersValues}/>
          
      <Box id='account_list'>
        {account_list.length > 0 ? (
          <TableContainer>
            <Table stickyHeader aria-label="sticky table"> 
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell align='right'>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  {account_list.map((account:any,i:any) => {

                    //convert microseconds account.running_config.sleep_time to seconds
                    const speed_time = account.running_config.sleep_time / 1000000;
                  return (
                    <TableRow 
                      key={i}
                      hover
                      role = "checkbox"
                    >
                      <TableCell>
                      <span className='gray_text'>{account.account_id}</span>
                      </TableCell>
                      <TableCell>
                        <div className='FlexContainer'>  
                          <Button
                            variant="contained"
                            color="success"
                            disabled={account.status === "running" || account.status === "paused"}
                            startIcon={account.status === "running"? <CircularProgress sx={{color: "white"}} size={16} /> : account.status === "paused"? <TimerIcon /> : <PlayArrowIcon />}
                          
                            onClick={() => {
                              setActiveItem(account);
                              setAlertOpen(true);
                            }}
                          >
                            {account.status === "running" && "Running"}
                            {account.status === "paused" && "Waiting"}
                            {account.status === "stopped" && "Start"}
                          </Button>
                          

                          {(account.status === "running" || account.status === "paused") && (
                              <Button
                                variant="contained"
                                color="error"
                                style={{marginRight: "10px"}}
                                startIcon={<StopIcon sx={{color: "white"}}/>}
                                fullWidth
                                onClick={() => {
                                  setAccountIdToStop(account.account_id);
                                  setDialogForcedStop(true); 
                                }}
                              >
                                Stop
                              </Button>
                          )}
                          <Scheduler 
                            account_id={account.account_id}
                            status={account.scheduler_status}
                            loadList={loadList}
                            disabled={account.status === "running" || account.status === "paused"}
                          />
                        </div>
                      </TableCell>
                      
                      <TableCell>
                        <div className='flexTd'>
                          <span className='flexTd_bold'>{account.email}</span>

                          {!reseller_id && (
                            <div className='speeds' onClick={() => {
                              setOpenTimeForm(true);
                              setActiveItem(account);
                            }}>
                              <Tooltip title="Running / Stop" placement="top">
                                <Chip label={`${account.running_config.running_minutes} / ${account.running_config.stop_minutes} min`} color="success" size="small" variant='outlined' />
                              </Tooltip>
                                
                              <Tooltip title="Rate Sleep" placement="top">
                                <Chip label={`${account.running_config.rate_sleep} min`} color="error" size="small" variant='filled' />
                              </Tooltip>

                              <Tooltip title="Search Speed" placement="top">
                                <Chip icon={<SpeedIcon />} 
                              label={`${speed_time} sec`} color="primary" size="small" variant='outlined' />
                              </Tooltip>

                              <Tooltip title="Effectiveness rate" placement="top">
                                <Chip icon={<MultilineChartIcon />} 
                              label={`${account.effectiveness_rate} %`} color="secondary" size="small" variant='outlined' />
                              </Tooltip>
                              
                              

                            </div>
                          )}
                        </div>
                        
                      </TableCell>


                      <TableCell align='right' style={{whiteSpace: "nowrap"}}>
                        {/* <ISwitch 
                          checked={account.is_active}
                          onChange={() => {
                            changeActiveStatus(account.account_id, !account.is_active)
                          }}
                        /> */}
                        <div className='flex_row'>
                          {account.time_left > 0 ? (
                            <Chip label={`${account.time_left_label}`} color="primary" size="small" variant='outlined' />
                          ) : (
                            <Chip label="Expired" color="error" size="small" variant='outlined'/>
                          )}



                          

                        




                          
                          <AccountMenu 
                            setActiveItem={setActiveItem}
                            account={account}
                            setOpenOrders={setOpenOrders}
                            handleDelete={handleDelete}
                            account_id={activeId}
                            setAccountId = {setActiveId}
                            isReseller={isReseller}
                          />
                        </div>
                          

                      </TableCell>
                    </TableRow>
                  )})}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <div className="noProducts">
            <img src={ic_empty} alt=""/>
            <p>No accounts found</p>
          </div>
        )}
                  
        {total_pages > 1 && (
          <Pagination count={total_pages} page={page}  onChange={(event, value) => {
            setPage(value)
          }
          } 
          color="primary"
          style={{"marginTop": "20px","alignSelf": "center"}}
          />
        )}
      </Box>



      <Orders
        setLoading={setLoading}
        open={openOrders}
        setOpen={setOpenOrders}
        account_id={activeId}
        account={activeItem}
        loadList={loadList}
        setAccountId={setActiveId}
        reseller_id={reseller_id}
        isUser={false}
      />

      <TimeForm
        setLoading={setLoading}
        open={openTimeForm}
        setOpen={setOpenTimeForm}
        account={activeItem}
        loadList={loadList}
        setAccountId={setActiveId}
      />


      <AlertDialog 
        open={alertOpen} 
        setOpen={setAlertOpen} 
        send={startrunning}
        subtitle="Are you sure you want to start getting blocks?"
      />

      <Dialog 
        open={dialogForcedStop}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div className="dialog_header">
              <h2 className='h2-no-margin'>Stopping to Get Blocks"</h2>
              
          </div>
          <div className='flex-column-center'>
            <CircularProgress sx={{marginTop: "20px", marginBottom: "20px"}} size={30} />
            <p>Stopping to get blocks... Please wait</p>
          </div> 
        </DialogContent>
      </Dialog>


      <AmazonLogs 
        setLoading={setLoading}
        activeAccount={activeItem} 
        isReseller={isReseller} 
      />

      <AlertDialog 
        open={deleteAlertOpen} 
        setOpen={setDeleteAlertOpen}
        send={deleteItem}
        subtitle="Are you sure you want to delete this account?"
      />

      
 
    </div>
  );
});



