import { Autocomplete, Button, Chip, createFilterOptions, createTheme, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, MenuItem, Modal, Paper, Select, styled, Switch, SwitchProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material"
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "elements/Box";
import ic_dots from 'icons/ic_dots.svg';
import {ProjectConfig} from 'Global';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import EventIcon from '@mui/icons-material/Event';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { stringify } from "querystring";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

import { TimePicker } from "@mui/x-date-pickers";
import moment from "moment";
import Cookies from 'universal-cookie';
import { useSnackbar, withSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import ISwitch from "elements/ISwitch";
import Scheduler from "./Scheduler";

const cookies = new Cookies();




const IOSSwitch = styled((props: SwitchProps) => (
  <Switch className="warehouse_switch" focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));


 //TODAY DATE FORMATED MM/DD/YYYY
 const today = new Date('2014-08-18T21:11:54')

export default withSnackbar(
    function AmazonAccountsEditor(props: any) {


    const role_id = cookies.get('role_id');
    
  
    /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    /*============================================= */

    const {setLoading} = props;

  /*=======================================
    GET USER ID FROM URL OR FROM ACCOUNT USER ID
  =======================================*/
  let {account_id } = useParams();
  account_id = props.account_id ? props.account_id : account_id;
  /*=======================================
  =======================================*/

  const [AmazonResult, setAmazonResult] = useState<any>([]);
  const [serviceAreaPoolList, setServiceAreaPoolList] = useState([]);

  useEffect(() => {
    setLoading(false);
  }, []);
  

  const navigate = useNavigate();
  const defaultValues: any = {
    account_id: account_id? account_id : "null",
    name: "",
    email: "",
    password: "",
    phone: "",
    is_active: true,
    filters:{
      minBlockRate: 20,
      minRatePerHour: 10,
      hours: {
        startTime: "00:00",
        endTime: "23:55",
      },
      weekdays: {
        Monday: true,
        Tuesday: true,
        Wednesday: true,
        Thursday: true,
        Friday: true,
        Saturday: true,
        Sunday: true
      },
      warehouses: {},

    },
    timezone: "America/New_York",
  };
  


  const [formValues, setFormValues] = useState(defaultValues)
  const [loadingButton, setLoadingButton] = useState(false);


  const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };


  const [showManualLogin, setShowManualLogin] = useState(false);
  const [urlManualToken, setUrlManualToken] = useState("");
  const [manualToken , setManualToken] = useState("");

  const handleAmazonLogin = () => {

    let formdata = new FormData();
    formdata.append('request', 'amazon_login');
    formdata.append('formdata', JSON.stringify(formValues));
    formdata.append('manual_token', manualToken);

    setLoadingButton(true);
    axios.post(ProjectConfig.api_url, formdata,
      {
        headers:{
          'LTKN': cookies.get('ltkn'),
        }
      })
        .then((response) => {
          console.log(response.data);
          setAmazonResult(response.data);
          setLoadingButton(false);
          
          

          if(response.data.status=='success'){
            setTokenStatus(true);
            enqueueSnackbar(response.data.message, { variant: 'success' });
            //change url and delete previous url
            navigate(`/amazon-accounts/edit/${response.data.account_id}`, { replace: true });
            //update account_id 
            setFormValues({
              ...formValues,
              account_id: response.data.account_id,
            });
          }
          else{
            setShowManualLogin(true);
            enqueueSnackbar("Error trying to connect account", { variant: 'error' });
          }
        })
        .catch((err) => {
          console.log(err.data);
        })
  }



  const handleWarehouse = () => {
      axios
          .get(ProjectConfig.api_url, {
            params: {
              request: "get_warehouses",
              account_id: account_id,
            },
          })
          .then((response) => {
            console.log("warehouse",response.data);
            if(response.data.status=='success'){
              setServiceAreaPoolList(response.data.warehouses);
            }
            else{
              enqueueSnackbar("Token Error", { variant: 'error' });
            }
          }
          )
          .catch((err) => {
            console.log(err.data);
            props.setLoading(false);
          }
          );
  }

  console.log("formValues",formValues);

  const handleSubmit = () => {


    //check if scheduler_status is true values are not empty
    if(formValues.scheduler_status && (formValues.running_schedule.start == "" || formValues.running_schedule.end == "")){
      enqueueSnackbar("Please complete the scheduler fields", { variant: 'error' });
      return;
    }


    setLoading(true);

    axios.post(ProjectConfig.api_url, formValues,
      {
        headers:{
          'LTKN': cookies.get('ltkn'),
        },
        params: {
          request: 'add_edit_af_account',
        }
      })
        .then((response) => {
          console.log(response.data);
          setLoading(false)
          if(props.account_user_id){
            //UPDATE LOCAL STORAGE IMAGE
            window.location.reload();
          }
          else{
            navigate(-1);
            
          }
           
        })
        .catch((err) => {
          console.log(err.data);
        })
        
  };



  /**/

  useEffect(() => {
    if (account_id) {
      axios
        .get(ProjectConfig.api_url, {
          params: {
            request: "af_account_details", 
            account_id: account_id,
          },
        })
        .then((response) => {
          console.log(response.data);
          setFormValues(response.data);

          setLoading(false);
          setTokenStatus(response.data.token_status)
          
        })
        .catch((err) => {
          console.log(err.data);
        });
    }
  }, []);


  const updateWarehouseSettings = (warehouse: any) => {
    axios
        .post(ProjectConfig.api_url,null, {
          params: {
            request: "update_warehouse_settings",
            account_id: account_id,
            warehouse: warehouse
          },
        })
        .then((response) => {
          console.log(response.data);
        })
        .catch((err) => {
          console.log(err.data);
        });  
  }

  const [tokenStatus, setTokenStatus] = useState(false);


  {/* FUNCTION OPEN POPUP WINDOW AND WAIT FOR CLOSES TO GET THE FINAL URL */}


  console.log("filters",formValues.filters);

  const openPopup = () => {
    const url = AmazonResult.challenge_uri

    
    //AVOID CROSS ORIGIN ERROR USING LISTENER
    window.addEventListener('message', (event) => {
      if(event.data){
        console.log(event.data);

      }
    }
    , false);

    //OPEN POPUP
    const popup = window.open(url, 'Amazon Login', 'width=600,height=600');
    if (popup) popup.focus();


    //CONSOLE LOG URL 
    console.log(popup?.location.href);


  };




  const handleManualCheck = () => {
    

      
      try {
        setLoadingButton(true);
        let access_token = urlManualToken.split("access_token=")[1].split("&")[0].replace("%7C", "|");
        setManualToken(access_token);
        
      }
      catch (error) {
        setManualToken("");
        enqueueSnackbar("URL no válida", { variant: 'error' });
      }
  }


  useEffect(() => {
    if (manualToken) {
      handleAmazonLogin();
    }
  }, [manualToken]);





  return (
    <>

    {!tokenStatus? (
      <Box id="loginAmazon" title='Amazon account' subtitle="Is necessary to connect your account.">
        
        <form onSubmit={handleSubmit} className='form'>
          <TextField
            id="name"
            name="name"
            label="Name"
            type="text"
            required
            fullWidth
            value={formValues.name}
            onChange={handleInputChange}
          />

          <TextField
            id="email"
            name="email"
            label="Email"
            type="text"
            required
            fullWidth
            value={formValues.email}
            onChange={handleInputChange}
          />

          <TextField
            id="password"
            name="password"
            label="Password"
            type="password"
            required
            fullWidth
            value={formValues.password}
            onChange={handleInputChange}
          />

          <LoadingButton
            size="small"
            type="button"
            loading={loadingButton}
            fullWidth
            onClick={handleAmazonLogin}
            variant="contained"
            sx={{ fontSize: '13px', padding: '10px 0px'}}
          >
            Log in
          </LoadingButton>

          <div id="AmazonResult">
            {/* parse html  */}
            <div dangerouslySetInnerHTML={{ __html: AmazonResult.message }} />
          </div>

          <Button
            size="small"
            fullWidth
            onClick={openPopup}
            variant="contained"
            sx={{ fontSize: '13px', padding: '10px 0px'}}
          >
            
            Open Amazon Login
          </Button>

          
          {showManualLogin && (
            <>
          
          <TextField
            id="urlManualToken"
            name="urlManualToken"
            label="URL"
            type="text"
            required
            fullWidth
            value={urlManualToken}
            onChange={(e) => setUrlManualToken(e.target.value)}
          />
            


          <Button
            size="small"
            fullWidth
            onClick={handleManualCheck}
            variant="contained"
            sx={{ fontSize: '13px', padding: '10px 0px'}}
          >
            Verify URL
          </Button>
          </>
          )}




        </form>
        
      </Box>

    ):(
      <>

      
      <div id="configAmazon">

        {tokenStatus && (
        <div id="amazonAccountStatus">
          <div className="amazonAccountStatusText">
            <h2>Amazon account</h2>
            <span>Connection status</span>
          </div>
          <div className="amazonAccountStatusIcon">
            <Chip label="Connected" variant="outlined" sx={{ borderColor: '#fff', color: '#fff' }} />
          </div>
        </div>
        )}
        <Box id="configAmazonFilters" title='Settings' subtitle="Configure your filters.">

            {role_id == 2 && (
              <div id='userInfo' className='outlinedGroup'>

                <div className="userInfoRow">
                  <span className="userInfoLabel">Name: </span>
                  <span className="userInfoValue">{formValues.name}</span>
                </div>

                <div className="userInfoRow">
                  <span className="userInfoLabel">Email: </span>
                  <span className="userInfoValue">{formValues.email}</span>
                </div>

                <div className="userInfoRow">
                  <span className="userInfoLabel">Password: </span>
                  <span className="userInfoValue">{formValues.password}</span>
                </div>

                <div className="userInfoRow">
                  <span className="userInfoLabel">Account ID: </span>
                  <span className="userInfoValue">{formValues.account_id}</span>
                </div>

                <div className="userInfoRow">
                  <span className="userInfoLabel">Phone: </span>
                  <span className="userInfoValue">{formValues.phone}</span>
                </div>
              </div>
            )}

            <div id='userInfo' className='outlinedGroup'>
            {/* timezone select */}
            <FormControl fullWidth>
              <InputLabel id="timezone-select-label">Timezone</InputLabel>
              <Select
                labelId="timezone-select-label"
                id="timezone-select"
                value={formValues.timezone}
                label="Timezone"
                onChange={(e) => {
                  formValues.timezone = e.target.value;
                  setFormValues({...formValues});
                }}
              >
                  <MenuItem key={"America/New_York"} value="America/New_York">New York</MenuItem>
                  <MenuItem key={"America/Chicago"} value="America/Chicago">Chicago</MenuItem>
                  <MenuItem key={"America/Denver"} value="America/Denver">Denver</MenuItem>
                  <MenuItem key={"America/Los_Angeles"} value="America/Los_Angeles">Los Angeles</MenuItem>
                  <MenuItem key={"America/Anchorage"} value="America/Anchorage">Anchorage</MenuItem>
                  <MenuItem key={"America/Adak"} value="America/Adak">Adak</MenuItem>
                  <MenuItem key={"Pacific/Honolulu"} value="Pacific/Honolulu">Pacific / Honolulu</MenuItem>
              </Select>
            </FormControl>
            </div>



          
          <div>
            
            <div id='filtersWeekdays' className='outlinedGroup'>
              <h2>Weekdays</h2>
              <div className="checkGroupColumn">

              <ISwitch checked={formValues.filters.weekdays.Monday} label="Monday" onChange={() => {
                setFormValues({...formValues, filters: {...formValues.filters, weekdays: {...formValues.filters.weekdays, Monday: !formValues.filters.weekdays.Monday}}});
              }}/>

              <ISwitch checked={formValues.filters.weekdays.Tuesday} label="Tuesday" onChange={() => {
                setFormValues({...formValues, filters: {...formValues.filters, weekdays: {...formValues.filters.weekdays, Tuesday: !formValues.filters.weekdays.Tuesday}}});
              }}/>

              <ISwitch checked={formValues.filters.weekdays.Wednesday} label="Wednesday" onChange={() => {
                setFormValues({...formValues, filters: {...formValues.filters, weekdays: {...formValues.filters.weekdays, Wednesday: !formValues.filters.weekdays.Wednesday}}});
              }}/>

              <ISwitch checked={formValues.filters.weekdays.Thursday} label="Thursday" onChange={() => {
                setFormValues({...formValues, filters: {...formValues.filters, weekdays: {...formValues.filters.weekdays, Thursday: !formValues.filters.weekdays.Thursday}}});
              }}/>

              <ISwitch checked={formValues.filters.weekdays.Friday} label="Friday" onChange={() => {
                setFormValues({...formValues, filters: {...formValues.filters, weekdays: {...formValues.filters.weekdays, Friday: !formValues.filters.weekdays.Friday}}});
              }}/>

              <ISwitch checked={formValues.filters.weekdays.Saturday} label="Saturday" onChange={() => {
                setFormValues({...formValues, filters: {...formValues.filters, weekdays: {...formValues.filters.weekdays, Saturday: !formValues.filters.weekdays.Saturday}}});
              }}/>

              <ISwitch checked={formValues.filters.weekdays.Sunday} label="Sunday" onChange={() => {
                setFormValues({...formValues, filters: {...formValues.filters, weekdays: {...formValues.filters.weekdays, Sunday: !formValues.filters.weekdays.Sunday}}});
              }}/>


              </div>
            </div> 

            <div id="filtersWarehouses">

              <h2>Warehouses</h2>

              
  

                <div id="Warehouses">

                  <Table>
                  <TableBody>
                    {serviceAreaPoolList.map((warehouse: any, index: any) => (
                      <TableRow key={index}>
                        <TableCell className="warehouse_switch">
                          <FormControlLabel
                            checked={warehouse.is_active}
                            
                            control={
                              <IOSSwitch 
                                sx={{ m: 1 }}
                                
                                onChange={(e) => {
                                  const { checked } = e.target;
                                  warehouse.is_active = checked;
                                  setServiceAreaPoolList([...serviceAreaPoolList]);
                                  
                                  //update filters
                                  if(checked){

                                    const warehouseObj = {
                                      [warehouse.serviceAreaId] : {
                                        startTime: '00:00',
                                        endTime: '23:55',
                                        minTimeToArrive: 30,
                                        minBlockRate: 20,
                                        minRatePerHour: 10,
                                      }
                                    }
                                    formValues.filters.warehouses = {...formValues.filters.warehouses, ...warehouseObj};
                                  }else{
                                    delete formValues.filters.warehouses[warehouse.serviceAreaId];
                                    
                                  }
                                  setFormValues({...formValues});
                                }
                                }
                              />
                            }
                            label={warehouse.serviceAreaName}
                          />
                        </TableCell>
                        <TableCell className="warehouse_settings">
                          
                          {warehouse.is_active &&
                          <>
                          <div className="input_row input_row_equal">
                            <TextField
                              id="minTimeToArrive"
                              name="minTimeToArrive"
                              label="Min Time To Arrive"
                              type="number"
                              required
                              fullWidth
                              value={formValues.filters.warehouses[warehouse.serviceAreaId].minTimeToArrive}
                              onChange={
                                (e) => {
                                  formValues.filters.warehouses[warehouse.serviceAreaId].minTimeToArrive = e.target.value;
                                  setFormValues({...formValues});
                                }
                              }
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{endAdornment: ( 
                                <InputAdornment position="end">
                                  Mins
                                </InputAdornment>
                              ),}}
                            />
                          </div>
                            <div className="input_row input_row_equal">
                              <LocalizationProvider dateAdapter={AdapterMoment}>
                                {/* WAREHOUSE - START TIME */}
                                <TimePicker
                                  label="Start Time"
                                  value={moment(formValues.filters.warehouses[warehouse.serviceAreaId].startTime, "HH:mm")}
                                  ampm={false}
                                  minutesStep={5}
                                  // format = "HH:mm"
                                  inputFormat = "HH:mm"

                                  onChange={(newValue) => {
                                    formValues.filters.warehouses[warehouse.serviceAreaId].startTime = (newValue)? (newValue).format('HH:mm') : '00:00';
                                    setFormValues({...formValues});
                                  }}
                                  renderInput={(params) => <TextField {...params} />}
                                />
                                
                                {/* WAREHOUSE - END TIME */}
                                <TimePicker
                                  label="End Time"
                                  value={moment(formValues.filters.warehouses[warehouse.serviceAreaId].endTime, "HH:mm")}
                                  ampm={false}
                                  minutesStep={5}
                                  onChange={(newValue) => {
                                    formValues.filters.warehouses[warehouse.serviceAreaId].endTime = (newValue)? (newValue).format('HH:mm') : '00:00';
                                    setFormValues({...formValues});
                                  }}
                                  renderInput={(params) => <TextField {...params} />}
                                />
                              </LocalizationProvider> 
                              </div>
                              <div className="input_row input_row_equal">
                              <TextField
                                id="minBlockRate"
                                value={formValues.filters.warehouses[warehouse.serviceAreaId].minBlockRate}
                                onChange={(e) => {
                                  formValues.filters.warehouses[warehouse.serviceAreaId].minBlockRate = e.target.value;
                                  setFormValues({...formValues});
                                }}
                                label="Min Block Rate"
                                type="number"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                fullWidth
                                InputProps={{endAdornment: ( 
                                  <InputAdornment position="end">
                                    <AttachMoneyIcon />
                                  </InputAdornment>
                                ),}}
                              />

                              <TextField
                                id="minRatePerHour"
                                value={formValues.filters.warehouses[warehouse.serviceAreaId].minRatePerHour}
                                onChange={(e) => {
                                  formValues.filters.warehouses[warehouse.serviceAreaId].minRatePerHour = e.target.value;
                                  setFormValues({...formValues});
                                }}
                                label="Min Rate Per Hour"
                                fullWidth
                                type="number"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                InputProps={{endAdornment: ( 
                                  <InputAdornment position="end">
                                    <AttachMoneyIcon />
                                  </InputAdornment>
                                ),}}
                              />

                            </div>
                            </>
                          }
                          </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>

                  
                </div>
                <Button
                  variant="contained"
                  color="primary"
                  className="button_1"
                  onClick={handleWarehouse}
                >
                  Get Warehouses
                </Button>
            </div> 
          </div>





          <div className='buttons'>
                  <Button onClick={() => navigate(-1)} variant="outlined" className="button_2">Close</Button>
                  <Button onClick={handleSubmit} variant="contained" className="button_1">Save changes</Button>
                </div>
        </Box>
        </div>
      </>

      )}
    </>
  );
});